<template>
  <div class="non-invested-fund-cards" v-if="!investedCustomer || previousInvestor">
    <SectionTitle :text="$i18n.t('home.nonInvestedFunds.title')" />
    <div class="non-invested-fund-cards-grid">
      <div
        v-for="(fundRun, index) in availableFunds"
        :key="index"
        @click="$router.push({ name: 'investFundDetails', params: { fundRun } })"
      >
        <non-invested-fund-card :fundRun="fundRun" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import funds from "../../mixins/funds.js";
import SectionTitle from "./SectionTitle.vue";
import NonInvestedFundCard from "./NonInvestedFundCard.vue";

export default {
  name: "NonInvestedFundCards",

  components: {
    SectionTitle,
    NonInvestedFundCard,
  },

  mixins: [funds],

  computed: {
    ...mapGetters(["investedCustomer", "previousInvestor"]),

    availableFunds() {
      const excludedFunds = [7200, 9363, 9364, 9102];

      return this.getRunList().filter(
        run => !excludedFunds.includes(Number(run)),
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.non-invested-fund-cards-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;

  @media (max-width: 1280px) {
    grid-template-columns: repeat(2, 1fr);
    margin: 0 20px;
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
