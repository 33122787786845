<template>
  <div class="redemption-wrapper redemption-centered-wrapper">
    <div class="redemption-content">
      <div class="redemption-step-back">
        <v-icon color="#333333" size="22" @click="$router.go(-1)">
          mdi-arrow-left
        </v-icon>
      </div>

      <div class="redeem-from-fund-label" v-if="fundRun">
        <i18n
          path="redemptions.chooseRedemptionAmount.withdrawFromFund"
          tag="p"
        >
          <template v-slot:fundName>
            <span> {{ fundRun | fundShortName }} </span>
            <div class="inline-wrapper">
              <div
                :style="{ backgroundColor: fundData.color }"
                class="fund-icon-container center-in-page"
              >
                <v-icon color="#fff" size="12">
                  mdi-{{ fundData.icon }}
                </v-icon>
              </div>
            </div>
          </template>
        </i18n>
      </div>

      <h2 class="redemption-header">
        {{ $t("redemptions.chooseRedemptionAmount.title") }}
      </h2>

      <div class="available-balance">
        {{ $t("redemptions.chooseRedemptionAmount.availableAmount") }}
        ${{ availableBalance | currencyNoSymbol }}
      </div>

      <div class="redemption-flex-columns">
        <div class="redemption-column">
          <div class="custom-input-wrapper">
            <CustomAmountInput
              :initial-amount="0"
              :invalid="inputAmount > availableBalance"
              :errorHint="
                $t(
                  'redemptions.chooseRedemptionAmount.errorHints.availableBalanceExceeded',
                )
              "
              @on-enter="
                inputAmount < availableBalance && inputAmount > 0
                  ? confirmRedemptionAmount()
                  : {}
              "
              :width="$vuetify.breakpoint.xs ? '100%' : '420px'"
              @amount-ingressed="inputAmount = $event"
            />
          </div>

          <NewButton
            :text="$t('base.nextStep')"
            @on-click="confirmRedemptionAmount()"
            :disabled="invalidAmount"
          />
          <div class="cancel-text" @click="$router.push({ name: 'home' })">
            {{ $t("base.cancel") }}
          </div>
        </div>

        <div class="redemption-column">
          <RedemptionHints />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import funds from "@/mixins/funds.js";
import CustomAmountInput from "@/components/shared/CustomAmountInput.vue";
import RedemptionHints from "@/components/redemptions/RedemptionHints.vue";
import NewButton from "@/components/shared/NewButton.vue";

export default {
  name: "ChooseRedemptionAmount",

  data: () => ({
    inputAmount: 0,
  }),

  mixins: [funds],

  components: {
    CustomAmountInput,
    NewButton,
    RedemptionHints,
  },

  computed: {
    ...mapGetters([
      "fundsBalances",
      "fundsResults",
      "redemption",
    ]),

    fundRun() {
      if (this.redemption.redemptionOptions.fromTotal) return null;
      return Number(
        this.redemption.redemptionList.find(fund => fund).fundRun,
      );
    },

    fundData() {
      if (!this.fundRun) return {};
      return this.getFundStaticData(this.fundRun);
    },

    invalidAmount() {
      return (
        this.inputAmount > this.availableBalance || this.inputAmount < 1000
      );
    },

    availableBalance() {
      if (this.fundRun) return this.fundsBalances[this.fundRun].fundAvailableBalance;
      return this.fundsResults.reduce(
        (total, fund) => total + fund.fundAvailableBalance,
        0,
      );
    },
  },

  methods: {
    ...mapActions(["setRedemptionData"]),

    getFundRedemptionQuotas(fund, redemptionAmount) {
      const { clpQuotaValue, availableQuotas } =
        this.fundsBalances[fund.fundRun].fundSeriesDetails;
      let fundRedemptionAmount = redemptionAmount;

      // If the user is redeeming from the total balance, we need to calculate the proportional amount to be redeemed

      if (this.redemption.redemptionOptions.fromTotal) {
        const { fundAvailableBalance } = this.fundsBalances[fund.fundRun];
        fundRedemptionAmount = Math.round(
          (fundAvailableBalance / this.availableBalance) * redemptionAmount,
        );
      }

      // Calculate the proportional amount to be redeemed from this fund
      const quotasRequested = fundRedemptionAmount / clpQuotaValue;

      // Format the quotas requested to 4 decimal places
      const formattedQuotasRequested =
        Math.round(quotasRequested * 10000) / 10000;

      const differenceFromTotal = Math.abs(
        formattedQuotasRequested - availableQuotas,
      );

      //  If the user is redeeming almost the same as the total available balance, we return the total amount
      if (differenceFromTotal < 1) {
        return availableQuotas;
      }

      // Return the proportional amount in quotas
      return formattedQuotasRequested;
    },

    createProportionalRedemption(redemptionAmount) {
      // If this redemption is from total balance
      // we're going to extract a proportional amount in quotas
      // from each customer funds (quotasRequested = fundRedemptionAmount / quotaValue)

      const redemptionList = this.fundsResults
        .filter(fundBalance => fundBalance.fundAvailableBalance > 0)
        .map(fund => ({
          fundRun: fund.fundRun,
          fundSeriesId: fund.fundSeriesDetails.fundSeriesId,
          // The user setted amount might be different to the amount that is going to be sent to
          // the operations API.
          // we sent the amount in QUO, but we still need to display it as CLP in remaining steps.
          requestedInputAmount: Math.round(
            (fund.fundAvailableBalance / this.availableBalance) *
              redemptionAmount,
          ),
          requestedAmount: this.getFundRedemptionQuotas(fund, redemptionAmount),
          requestedAmountCurrency: "QUO",
        }));
      // We filter out the redemptions that resulted in an amount of 0. This might
      // happen in cases were the current balance between fund is too large and results in
      // the proportion being 0 for a specific fund.
      const filteredRedemptionList = redemptionList.filter(
        redemption => redemption.requestedAmount > 0,
      );

      this.setRedemptionData({
        ...this.redemption,
        redemptionList: filteredRedemptionList,
        redemptionAmount,
      });
    },

    createFundRedemption(redemptionAmount) {
      const fundRedemption = this.redemption.redemptionList.find(
        fund => Number(fund.fundRun) === this.fundRun,
      );

      const quotasRequested = this.getFundRedemptionQuotas(
        fundRedemption,
        redemptionAmount,
      );

      const redemptionList = [
        {
          ...fundRedemption,
          requestedInputAmount: redemptionAmount,
          requestedAmount: quotasRequested,
          requestedAmountCurrency: "QUO",
        },
      ];

      this.setRedemptionData({
        ...this.redemption,
        redemptionList,
        redemptionAmount,
      });
    },

    confirmRedemptionAmount() {
      if (this.redemption.redemptionOptions.fromTotal) {
        this.createProportionalRedemption(this.inputAmount);
      } else {
        this.createFundRedemption(this.inputAmount);
      }
      this.$router.push({ name: "confirmBankAccount" });
    },
  },
};
</script>

<style lang="scss" scoped>
.fund-icon-container {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.inline-wrapper {
  display: inline-block;
  margin-left: 5px;
}

.redeem-from-fund-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}

.available-balance {
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
  color: #333333;
  margin-bottom: 40px;
}

.custom-input-wrapper {
  margin-bottom: 20px;
}

.total-redemption-checkbox {
  margin-bottom: 40px;
}

.redemption-content {
  max-width: 870px;
}
</style>
