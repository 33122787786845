<template>
  <div class="view-wrapper">
    <div class="view-content" v-if="!loading">
      <div class="header-container">
        <div class="header-title">
          <v-icon color="#333333" @click="$router.go(-1)"> mdi-arrow-left </v-icon>
          <div class="investment-details-title">
            {{
              fundRun
                ? $i18n.t("home.balance.fundDetails")
                : $i18n.t("home.balance.investmentDetails")
            }}
          </div>
        </div>
        <div
          class="last-update"
          v-html="$t('home.balance.updatedAt', { weekday: closingDay })"
          v-if="closeDate && investedCustomer"
        />
      </div>

      <!-- Invesment fund card, only when a is any fund run in query params on desktop -->
      <div
        class="invest-fund-card balance-section"
        v-if="fundRun && $vuetify.breakpoint.smAndUp"
      >
        <InvestFundCard :fundRun="Number(fundRun)" :canInvest="canInvest" />
      </div>
      <!-- Balance history chart -->

      <div class="balance-section chart-container">
        <div class="detailed-fund" v-if="fundRun && this.$vuetify.breakpoint.xs">
          <fund-badge :fundRun="fundRun" :size="40" :iconSize="20" />
          <span> {{ fundRun | fundShortName }}</span>
        </div>
        <div class="current-balance-label">
          {{ $i18n.t("home.balance.balancesSections.historyChart.labels.totalBalance") }}
        </div>
        <div class="current-balance-amount">
          {{ currentBalance.currencyAmount | formatBalanceAmount("CURRENT") }}
        </div>

        <BalanceChart :fundRun="fundRun" />

        <NewButton
          :text="$i18n.t('base.invest')"
          :height="50"
          :icon="'mdi-plus-circle-outline'"
          v-if="fundRun ? $vuetify.breakpoint.smAndDown : $vuetify.breakpoint.mdAndDown"
          @on-click="handleInvestButton()"
        />
      </div>

      <!-- Breakdown and results details -->
      <div
        class="balance-section"
        v-for="(section, index) in balanceSections"
        :key="index"
      >
        <div class="balance-section-title">
          {{ $i18n.t(`home.balance.balancesSections.${section.name}.title`) }}
        </div>
        <div
          v-for="(balanceItem, index) in section.data"
          :key="index"
          class="details-item details-row"
          :class="[balanceIsClickable(balanceItem) ? 'clickable-item' : '']"
          @click="openDetailsDialog(balanceItem, section.name)"
        >
          <div class="balance-name">
            {{
              $i18n.t(
                `home.balance.balancesSections.${section.name}.balances.${balanceItem.name}`,
              )
            }}
            <v-icon
              v-if="balanceItem.explainable && fundRun"
              color="#333333"
              class="ml-2"
              small
            >
              mdi-information-outline</v-icon
            >
          </div>
          <div class="balance-amount">
            {{ balanceItem.details.amount | formatBalanceAmount(balanceItem.name) }}
          </div>
        </div>
      </div>

      <!-- Current details by fund (as the funds section on home view) -->
      <div class="balance-section" v-if="!fundRun">
        <div class="balance-section-title">
          {{ $i18n.t("home.balance.balancesSections.funds.title") }}
        </div>
        <div
          class="customer-fund"
          v-for="(fund, index) in customerFunds"
          :key="index"
          @click="
            $router.push({ name: 'detailedFund', params: { fundRun: fund.fundRun } })
          "
        >
          <FundCard :fundBalance="fund" class="clickable-item" />
        </div>
      </div>

      <!-- Balance detailed by specific fund -->
      <div class="fund-description-sections" v-else>
        <div class="balance-section">
          <div class="fund-description-title">
            {{ $i18n.t("home.balance.fundDescription") }}
          </div>
          <div class="fund-description-text">
            {{ $i18n.t(`fund.description.${fundRun}`) }}
          </div>
        </div>
        <div class="balance-section fund-table">
          <div class="table-title">
            {{ $i18n.t("home.balance.details") }}
          </div>
          <TwoColumnsTable :tableData="fundTableData" />
        </div>
        <div class="fund-page-btn-container">
          <NewButton
            :fontSize="14"
            :height="50"
            :buttonClass="'black-outlined'"
            :text="$i18n.t('home.balance.fundPage')"
            :link="`https://www.dvacapital.com/fondos/${fundRun}`"
          />
        </div>
      </div>

      <InvestmentDetailsDialog
        :dialog="detailsDialog"
        :balanceData="detailedBalance"
        :title="dialogTitle"
        :explanation="detailedBalance.explainable"
        @display="detailsDialog = $event"
        v-if="detailsDialog"
      />

      <MessageDialog
        :dialog="explanationDialog"
        :title="dialogTitle"
        :message="explanationText"
        @display="explanationDialog = $event"
      />
    </div>

    <LoadingCard v-if="loading" fullScreen />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import funds from "@/mixins/funds.js";
import FundBadge from "@/components/shared/FundBadge.vue";
import BalanceChart from "@/components/results/BalanceChart.vue";
import NewButton from "@/components/shared/NewButton.vue";
import FundCard from "@/components/shared/FundCard.vue";
import InvestFundCard from "@/components/shared/InvestFundCard.vue";
import TwoColumnsTable from "@/components/shared/TwoColumnsTable.vue";
import InvestmentDetailsDialog from "@/components/results/InvestmentDetailsDialog.vue";
import MessageDialog from "@/components/shared/MessageDialog.vue";
import LoadingCard from "@/components/shared/LoadingCard.vue";

export default {
  name: "InvestmentDetails",

  data: () => ({
    detailsDialog: false,
    explanationDialog: false,
    detailedBalance: {},
    dialogTitle: "",
    explanationText: null,
  }),

  components: {
    FundCard,
    InvestmentDetailsDialog,
    BalanceChart,
    NewButton,
    FundBadge,
    InvestFundCard,
    TwoColumnsTable,
    MessageDialog,
    LoadingCard,
  },
  mixins: [funds],

  props: ["fundRun"],

  computed: {
    ...mapGetters([
      "loading",
      "fundsResults",
      "customerCurrent",
      "fundsBalances",
      "authenticatedStatus",
      "signupStatus",
      "closeDate",
      "investedCustomer",
    ]),

    canInvest() {
      const { authenticatedStatus, fundRun, signupStatus } = this;
      const { completedContractCompass, completedContract } = signupStatus;

      const excludedFunds = [7200, 9363, 9364, 9102];

      if (Number(fundRun) === 10275) {
        return completedContractCompass;
      }

      if (Number(fundRun) && excludedFunds.includes(Number(fundRun))) {
        return false;
      }

      if (authenticatedStatus === "authenticated" || completedContract) {
        return true;
      }

      return false;
    },

    balanceSections() {
      return [
        { data: this.results, name: "results" },
        { data: this.breakdown, name: "breakdown" },
      ];
    },

    breakdown() {
      // the order is important for display
      // the keys are defined by the API

      return [
        {
          name: "DEPOSITS",
          details: this.getFilteredBalance("fundDeposits"),
          explainable: false,
        },
        {
          name: "WITHDRAWALS",
          details: this.getFilteredBalance("fundWithdrawals"),
          explainable: false,
        },
        {
          name: "RESULT",
          details: this.getFilteredBalance("fundHistoricalResult"),
          explainable: true,
        },
        {
          name: "DISTRIBUTIONS",
          details: this.getFilteredBalance("fundDistributions"),
          explainable: true,
        },
        {
          name: "DISTRIBUTION_REINVESTMENTS",
          details: this.getFilteredBalance("fundDistributionReinvestments"),
          explainable: true,
        },
        {
          name: "CURRENT",
          details: this.getFilteredBalance("fundCurrent"),
          explainable: false,
        },
      ];
    },

    results() {
      return [
        {
          name: "WITHDRAWN_RESULT",
          details: this.getFilteredBalance("fundWithdrawnResult"),
          explainable: true,
        },
        {
          name: "CURRENT_RESULT",
          details: this.getFilteredBalance("fundCurrentResult"),
          explainable: true,
        },
        {
          name: "RESULT",
          details: this.getFilteredBalance("fundHistoricalResult"),
          explainable: true,
        },
      ];
    },

    customerFunds() {
      return this.fundsResults
        .filter(
          (v, i, a) => a.findIndex(t => t?.fundRun === v?.fundRun && t.fundDeposits > 0) === i,
        )
        .sort((fundA, fundB) => (fundB?.fundCurrent || 0) - (fundA?.fundCurrent || 0));
    },

    /** The following computed properties are for the detail of a particular fund */

    currentBalance() {
      if (!this.fundRun) {
        const fundsCurrent = this.customerCurrent.map(fund => fund?.currencyAmount);
        if (fundsCurrent.length === 0) return { currencyAmount: 0 };
        return {
          currencyAmount: fundsCurrent.reduce((total, amount) => total + amount),
        };
      }
      const fundBalance = this.customerCurrent.find(
        fund => fund.fundRun === this.fundRun && fund.currencyAmount > 0,
      );

      return {
        currencyAmount: fundBalance?.currencyAmount || 0,
        seriesName: fundBalance?.fundSeriesName,
        nemo: fundBalance?.symbol,
        quotas: fundBalance?.quotasAmount,
        quotaValue: fundBalance?.quotaValue,
        exchangeRate: fundBalance?.exchangeRate,
        currency: fundBalance?.currency,
      };
    },

    fundTableData() {
      if (!this.loading) {
        const staticData = this.getFundStaticData(this.fundRun);
        /**
         * if the table is for an invested fund,
         * the client's balance sheet is shown in detail
         */
        const hasBalance = this.customerCurrent.find(
          fund => fund?.fundRun === this.fundRun && fund?.currencyAmount > 0,
        );
        if (hasBalance) {
          const { exchangeRate, currency } = this.currentBalance;

          const fundResults = this.fundsResults.find(
            fund => fund.fundRun === this.fundRun,
          );
          const fundData = [
            {
              key: this.$t("reusableTable.type"),
              value: this.$t(`funds.fundTypes.${staticData.fundType}`),
            },
            {
              key: this.$t("reusableTable.series"),
              value: this.currentBalance.seriesName,
            },
            {
              key: this.$t("reusableTable.currency"),
              value: this.$t(`currencies.${staticData.currency}`),
            },
            {
              key: this.$t("reusableTable.balance"),
              value: `${this.$options.filters.currencyNoSymbol(
                fundResults.fundCurrent,
              )} CLP `,
            },
            {
              key: this.$t("reusableTable.currentResults"),
              value: `${this.$options.filters.currencyNoSymbol(
                fundResults.fundCurrentResult,
              )} CLP `,
            },
            {
              key: this.$t("reusableTable.historicalResults"),
              value: `${this.$options.filters.currencyNoSymbol(
                fundResults.fundHistoricalResult,
              )} CLP `,
            },
            {
              key: this.$t("reusableTable.withdrawals"),
              value: `${this.$options.filters.currencyNoSymbol(
                fundResults.fundWithdrawals,
              )} CLP `,
            },
            {
              key: this.$t("reusableTable.quotas"),
              value: this.$options.filters.toQuotaNumber(this.currentBalance.quotas),
            },
            {
              key: this.$t("reusableTable.quotaValue"),
              value: `${this.$options.filters.toQuotaNumber(
                this.currentBalance.quotaValue,
              )} ${currency}`,
            },
            { key: this.$t("reusableTable.nemo"), value: this.currentBalance.nemo },
          ];
          /**
           * if it is an USD fund, the exchange rate will be greater than 1
           * therefore we insert the exchange rate into the table
           */
          if (staticData.currency === "USD") {
            fundData.splice(fundData.length - 1, 0, {
              key: this.$t("reusableTable.exchangeRate"),
              value: `1 USD = ${this.$options.filters.currencyNoSymbol(exchangeRate, 2)} CLP`,
            });
          }

          if (fundResults.fundWithdrawals === 0) {
            return fundData.filter(
              row => row.key.toLowerCase() !== "utilidad histórica" &&
                row.key.toLowerCase() !== "retiros",
            );
          }
          return fundData;
        }
        /**
         * if this fund is not an invested one, we will show characteristics about it
         * like the redemption period, fixed fee, ideal term, etc.
         */
        return [
          {
            key: this.$t("reusableTable.type"),
            value: this.$t(`funds.fundTypes.${staticData.fundType}`),
          },
          {
            key: this.$t("reusableTable.currency"),
            value: this.$t(`currencies.${staticData.currency}`),
          },
          {
            key: this.$t("reusableTable.idealTerm"),
            value: this.$t(`funds.timeHorizon.${staticData.timeHorizon}.years`),
          },
          { key: this.$t("reusableTable.fixedFee"), value: staticData.fixedFee },
          {
            key: this.$t("reusableTable.redemptionPeriod"),
            value: this.$t(`funds.redemptionPeriods.${staticData.redemptionPeriod}`),
          },
          {
            key: this.$t("reusableTable.location"),
            value: staticData.geoDistribution,
          },
        ];
      }
      return [{ key: "", value: "" }];
    },

    closingDay() {
      return this.$options.filters.getClosingDay(this.closeDate);
    },
  },

  methods: {
    handleInvestButton() {
      const targetRoute = this.fundRun ? "investChooseInvestmentAmount" : "investViewFunds";
      const params = this.fundRun ? { fundRun: this.fundRun } : {};

      this.$router.push({
        name: targetRoute,
        params,
      });
    },

    getFilteredBalance(balanceName) {
      if (Object.keys(this.fundsBalances).length === 0) return { amount: 0, balanceBySeries: [] };

      const balancesCollection = this.fundRun ?
        [this.fundsBalances[this.fundRun]] :
        this.fundsResults;

      const specificBalance = balancesCollection.map(fund => ({
        currencyAmount: fund[balanceName],
        fundRun: fund.fundRun,
      }));

      const totalByBalance = balancesCollection
        .map(fund => fund[balanceName])
        .reduce((total, balanceAmount) => total + balanceAmount, 0);

      return { amount: Math.round(totalByBalance), balanceBySeries: specificBalance };
    },

    openDetailsDialog(balanceItem, currentSection) {
      if (!this.balanceIsClickable(balanceItem)) return;
      const balanceType = balanceItem.name;
      this.dialogTitle = balanceItem.explainable ?
        this.$t(`home.balance.explanations.${balanceType}.title`) :
        null;
      this.explanationText = balanceItem.explainable ?
        this.$t(`home.balance.explanations.${balanceType}.text`) :
        null;
      if (this.fundRun) {
        if (balanceItem.explainable) this.explanationDialog = true;
        return;
      }
      this.detailedBalance = balanceItem;
      this.dialogTitle = this.$t(
        `home.balance.balancesSections.${currentSection}.balances.${balanceType}`,
      );
      this.detailedBalance.balanceBySeries = this.detailedBalance.details.balanceBySeries.filter(
        fund => fund.currencyAmount !== 0,
      );
      this.detailsDialog = true;
    },

    balanceIsClickable(balanceItem) {
      // If we are in a fund details screen, only explainable balances are clickables
      if (this.fundRun) {
        return balanceItem.explainable;
      }
      // In the general detail view, all non-zero or explainable balances are clickable.
      return balanceItem.details.amount !== 0 || balanceItem.explainable;
    },
  },
};
</script>

<style lang="scss" scoped>
.view-wrapper {
  display: flex;
  justify-content: center;
  margin: 60px 20px;
  @media (max-width: 600px) {
    margin: 0;
  }
}

.view-content {
  max-width: 800px;
  width: 100%;
}

.balance-section {
  background: #ffffff;
  border-radius: 10px;
  width: 100%;
  padding: 30px;
  @media (max-width: 600px) {
    background-color: transparent;
    padding: 0 20px;
  }
}

.chart-container {
  @media (max-width: 600px) {
    background-color: #ffffff;
    padding: 25px 20px;
    border-radius: 0 0 10px 10px !important;
  }
}

.balance-section + .balance-section {
  margin-top: 20px;
  @media (max-width: 600px) {
    margin-top: 60px;
  }
}

.balance-section-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  color: #333333;
  margin-bottom: 20px;
}

.detailed-fund {
  display: flex;
  align-items: center;
  gap: 0 10px;
  margin-bottom: 20px;
  span {
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    color: #333333;
  }
}

.current-balance-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  margin-bottom: 10px;

  @media (max-width: 600px) {
    font-size: 16px;
    margin-bottom: 5px;
  }
}

.invest-fund-card {
  padding: 0 10px;
}

.current-balance-amount {
  font-weight: 600;
  font-size: 28px;
  line-height: 140%;
  color: #333333;
}

.details-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.details-item {
  height: 60px;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e2e0e0;
  border-radius: 10px;
  padding: 0px 30px;
  @media (max-width: 600px) {
    padding: 0px 20px;
  }
}

.details-item {
  .balance-name {
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 250px;
    @media (max-width: 600px) {
      min-width: 0;
      margin-right: 20px;
    }
  }
}

.details-item + .details-item {
  margin-top: 10px;
}

.details-row:last-child {
  margin-top: 40px;
  .balance-name {
    font-weight: 600;
  }
  .balance-amount {
    font-weight: 600;
  }
  @media (max-width: 600px) {
    margin-top: 20px;
  }
}

.balance-name,
.balance-amount {
  font-size: 14px;
  line-height: 16px;
  color: #333333;
}

.balance-amount {
  font-weight: 500;
}

.customer-fund {
  border: 1px solid #e2e0e0;
  border-radius: 10px;
}

.customer-fund + .customer-fund {
  margin-top: 10px;
}

.investment-details-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 160%;
  color: #333333;
  @media (max-width: 600px) {
    font-size: 16px;
  }
}

.fund-description-sections {
  margin-top: 20px;
  @media (max-width: 600px) {
    margin-top: 60px;
    background: #ffffff;
    border-radius: 10px;
    padding: 30px 0;
  }
}

.fund-description-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  color: #333333;
  margin-bottom: 20px;
}

.fund-description-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 160%;
}

.fund-table {
  padding: 30px 0;
  @media (max-width: 600px) {
    margin-top: 40px !important;
    padding: 0;
  }
}

.table-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: #333333;
  margin: 0 0 20px 20px;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  gap: 0 25px;
  margin-left: -50px;
  @media (max-width: 1024px) {
    margin-left: 0px;
  }
  @media (max-width: 600px) {
    height: 60px;
    background-color: #ffffff;
    margin: 0;
    padding: 0 20px;
    border-bottom: 1px solid #e2e0e0;
    gap: 0 15px;
  }
}

.header-title {
  display: flex;
  align-items: center;
  gap: 0 25px;
}

.fund-page-btn-container {
  margin-top: 40px;
  max-width: 290px;

  @media (max-width: 600px) {
    max-width: 100%;
    margin: 40px 20px 0px 20px;
  }
}

.last-update {
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
  color: #646363;
  @media (max-width: 960px) {
    font-size: 10px;
  }
  @media (max-width: 370px) {
    font-size: 8px;
  }
}
</style>
